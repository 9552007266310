/* eslint-disable no-underscore-dangle */
const cardSwitch = (card) => {
  let { link } = card;
  let tag = card.category;
  switch (card.__typename) {
    case 'ContentfulArticle':
      link = `/learn/${card.slug}`;
      break;
    case 'ContentfulLandingPageSections':
      link = `/${card.slug}`;
      break;
    case 'ContentfulCardAsset':
      link = card.downloadableAsset.file.url;
      if (card.downloadableAsset.file.contentType === 'application/pdf') {
        tag = 'pdf';
      } else if (card.downloadableAsset.file.contentType === 'application/zip') {
        tag = 'zip';
      }
      break;
    case 'ContentfulCardLink':
      tag = 'link';
      break;
    default:
      link = '/404';
  }
  return [link, tag];
};

export default cardSwitch;
