/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import useAlertBox from '@utils/useAlertBox';
import HeroImage from '@components/HeroImage';
import SponsorPlug from '@components/SponsorPlug';
import ContentCard from '@components/ContentCard';
import CardButton from '@components/CardButton';
import SponsorLockup from '@components/SponsorLockup';
import CTABanner from '@components/CTAContentful';
import MediaGallery from '@components/MediaGallery';
import withNullChecks from '@templates/HOC/withNullChecks';
import Title from '@components/Title';
import cardSwitch from '@utils/card-type-switch';
import HeadSEO from '@components/Navigation/HeadSEO';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import Breadcrumbs from '@components/Navigation/Breadcrumbs';
import { getHeadProps } from '@utils/navigationHelpers';
import rendererOptions from '../../../richtext-rendering/renderer-options';
import parseMediaRef from '../../../utils/parseMediaRef';
import useDeviceDetect from '../../../utils/useDeviceDetect';

import './index.scss';

export const PAGE_QUERY = graphql`
  query($slug: String!) {
    presentation: contentfulPresentationPage(slug: { eq: $slug }) {
      bannerHeading {
        banner {
          title
          gatsbyImageData(layout: FULL_WIDTH)
          file {
            url
          }
        }
        newTab
        titleBanner
        subtitle
        buttonText
        link
        sponsorLogos {
          url
          width
          logoImage {
            description
            gatsbyImageData
            file {
              url
            }
          }
          newTab
        }
        title
      }
      slug
      brandColorOverride
      descriptionSeo
      sponsorText1
      sponsorLogo1 {
        url
        width
        logoImage {
          description
          gatsbyImageData
          file {
            url
          }
        }
        newTab
      }
      sponsorText2
      sponsorLogo2 {
        url
        width
        logoImage {
          gatsbyImageData
          description
          file {
            url
          }
        }
        newTab
      }
      videoUrl
      sections {
        copy {
          raw
          references {
            ...RichTextReferences
          }
        }
        cards {
          ...AllCardTypes
        }
      }
      actions {
        title
        heading
        copy
        buttonText
        buttonUrl
        newTab
      }
      sponsorLockupText1
      sponsorLockupLogo1 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText2
      sponsorLockupLogo2 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText3
      sponsorLockupLogo3 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText4
      sponsorLockupLogo4 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      titleDisplay
      titleSeo
      cardTitle
      cardSubhead
      cardTeaser
      cardButtonText
      mediaGallery {
        ...MediaGalleryTypes
      }
      additionalDetails {
        raw
        references {
          ...RichTextReferences
        }
      }
    }
    breadCrumbs: contentfulPresentationPage(slug: { eq: $slug }) {
      titleBreadcrumb
      parentPage {
        ...ParentPageRecursive
      }
    }
    contentfulHomePage ( titleDisplay: { eq: "Home Page"} ) {
      alertBox {
        raw
        references {
          ...RichTextReferences
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const { presentation } = data;
  const {
    pageTitle, pageDescription, pageKeywords, pageImgUrl, pageAltText,
  } = getHeadProps(presentation);

  return (
    <HeadSEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pageKeywords={pageKeywords}
      pageImgUrl={pageImgUrl}
      pageAltText={pageAltText}
    />
  );
};

const PresentationTemplate = ({ data }) => {
  const { presentation, breadCrumbs } = data;
  const sponsorList = presentation.sponsorLogo1
    ? presentation.sponsorLogo1.map((logo) => (
      <SponsorPlug key={uuidv4()} logo={logo} />
    ))
    : null;

  const sponsorList2 = presentation.sponsorLogo2
    ? presentation.sponsorLogo2.map((logo) => (
      <SponsorPlug key={uuidv4()} logo={logo} />
    ))
    : null;

  const { isMobile } = useDeviceDetect();

  const { alertBoxData, hasAlert } = useAlertBox(data);

  return (
    <StandardPageWrapper alertBoxData={alertBoxData} hasAlert={hasAlert}>
      <Breadcrumbs parentData={breadCrumbs} />
      {presentation.bannerHeading && (
        <HeroImage
          buttonText={presentation.bannerHeading.buttonText}
          image={presentation.bannerHeading.banner}
          link={presentation.bannerHeading.link}
          subhead={presentation.bannerHeading.subtitle}
          title={presentation.bannerHeading.titleBanner}
          isTitleUnderlined
          logos={presentation.bannerHeading.sponsorLogos}
          newTab={presentation.bannerHeading.newTab}
        />
      )}
      <div>
        <Row className="no-gutters">
          <div className={presentation.additionalDetails ? 'upperBody' : 'container'}>
            <Col md={12}>
              {!presentation.bannerHeading && (
                <Title title={presentation.titleDisplay} titleSize="h2" classes="mt-4" />
              )}
              {(!isMobile && (sponsorList || sponsorList2))
                ? (
                  <>
                    <div className="margin-left">
                      {presentation.sponsorText1 && (
                        <span>{presentation.sponsorText1}</span>
                      )}
                      {' '}
                      {sponsorList}
                    </div>
                    <div style={presentation.additionalDetails ? { marginTop: 0 } : { marginTop: '15px' }} className="margin-left">
                      {presentation.sponsorText2 && (
                        <span>{presentation.sponsorText2}</span>
                      )}
                      {' '}
                      {sponsorList2}
                    </div>
                  </>
                ) : null}
              {isMobile ? (
                <div>
                  <div className={presentation.additionalDetails ? null : null}>
                    <div className="float-text-mobile">
                      {presentation.sponsorText1}
                    </div>
                    <div className="float-logos-mobile">
                      {sponsorList}
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className={presentation.additionalDetails ? null : 'sponsorText2-no-additional-details'}>
                    <div className="float-text-mobile">
                      {presentation.sponsorText2}
                    </div>
                    <div className="float-logos-mobile">
                      {sponsorList2}
                    </div>
                  </div>
                </div>
              ) : null}
              <div>
                {(presentation.videoUrl && !presentation.additionalDetails) && (
                  <div
                    className="iframe-container"
                    style={{ marginBottom: '20px', marginTop: '20px' }}
                  >
                    <iframe
                      allowFullScreen
                      frameBorder="0"
                      src={parseMediaRef(presentation.videoUrl)}
                      title={presentation.titleDisplay}
                    />
                  </div>
                )}
                {presentation.sections
                  && presentation.sections.map((section, index) => {
                    let borderBottomValue;
                    if (index > 0 && index < presentation.sections.length) {
                      borderBottomValue = '0';
                    }
                    if (index === presentation.sections.length - 1) {
                      borderBottomValue = '1px solid #ddd';
                    }
                    // allow content to use full width in sections under Additional Details
                    const width = index > 0 ? '100%' : null;

                    return (
                      <div key={uuidv4()}>
                        <Row
                          key={uuidv4()}
                          style={{
                            borderBottom: borderBottomValue,
                            width,
                          }}
                          className={presentation.additionalDetails ? (!isMobile ? 'body-text-presentation-page' : null) : (index !== 0 ? 'container-border row-padding' : 'row-padding')}
                        >
                          <Col sm={12} style={{ padding: '20px' }}>
                            {(presentation.videoUrl && presentation.additionalDetails && index === 0) && (
                              <div
                                className="iframe-container"
                                style={{ marginBottom: '20px', marginTop: '20px' }}
                              >
                                <iframe
                                  allowFullScreen
                                  frameBorder="0"
                                  src={parseMediaRef(presentation.videoUrl)}
                                  title={presentation.titleDisplay}
                                />
                              </div>
                            )}
                            {section.copy
                              && renderRichText(section.copy, rendererOptions)}
                          </Col>
                          {section.cards
                            && section.cards.map((card) => {
                              if (
                                card.__typename === 'ContentfulContentCard'
                              ) {
                                return (
                                  <Col key={uuidv4()} md={4} lg={4}>
                                    <ContentCard
                                      title={card.titleDisplay}
                                      body={card.body}
                                      button={card.button}
                                      imgSrc={card.cardImage}
                                      border={card.border}
                                      brandColorOverride={presentation.brandColorOverride}
                                    />
                                  </Col>
                                );
                              }

                              return (
                                <Col key={uuidv4()} md={4} lg={4}>
                                  <CardButton
                                    imgSrc={card.banner}
                                    body={card.tagline}
                                    link={cardSwitch(card)[0]}
                                    title={card.titleDisplay}
                                    titleSize="h3"
                                    tag={cardSwitch(card)[1]}
                                    altText={card.banner?.description}
                                    topics={card.topics}
                                    brandColorOverride={presentation.brandColorOverride}
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                        {(presentation.additionalDetails && index === 0)
                          && (
                            <div className={presentation.additionalDetails ? (isMobile ? 'additional-details-mobile' : 'additional-details') : null}>
                              {(presentation.additionalDetails)
                                && renderRichText(presentation.additionalDetails, rendererOptions)}
                            </div>
                          )}

                        <div className="clearfix" />
                      </div>
                    );
                  })}
              </div>
            </Col>
          </div>
        </Row>
        {(presentation.mediaGallery && <MediaGallery page={presentation} />)}
        {(presentation.sponsorLockupText1
          || presentation.sponsorLockupText2
          || presentation.sponsorLockupText3
          || presentation.sponsorLockupText4
          || presentation.sponsorLockupLogo1
          || presentation.sponsorLockupLogo2
          || presentation.sponsorLockupLogo3
          || presentation.sponsorLockupLogo4)
          && <SponsorLockup page={presentation} />}
      </div>
      {presentation.actions && <CTABanner actions={presentation.actions} brandColorOverride={presentation.brandColorOverride} />}
    </StandardPageWrapper>
  );
};

PresentationTemplate.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default withNullChecks({
  dataKey: 'presentation',
  typeName: 'presentationPage',
})(PresentationTemplate);
